import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "reactstrap";

const MyNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const gradientBackgroundStyle = {
    background: "linear-gradient(70deg,  #FCFCFC 10%, #FCFCFC 50%)",
  };

  const navLinkStyle = {
    marginLeft: "15px",
    marginRight: "15px",
    color: "#000",
    // fontWeight: "400",
    fontFamily: "Mulish, sans-serif",
    // fontStyle: "normal",
  };

  const buttonStyle = {
    backgroundColor: "#FF7223",
    borderColor: "#FF7223",
    fontWeight: "700",
    fontFamily: "Mulish, sans-serif",
    fontStyle: "normal",
    fontSize: "16px",
  };

  const centerNavStyle = {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  };

  const toggleButtonStyle = {
    border: "1px solid #121212",
    backgroundColor: "transparent",
  };

  const toggleIconStyle = {
    backgroundColor: "transparent",
  };

  const handleNavClick = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  return (
    <>
      <div className="justify-center">
        <Navbar
          bg="myRed"
          data-bs-theme="light"
          style={gradientBackgroundStyle}
          expand="lg"
        >
          <Container>
            <Navbar.Brand
              className="fw-bold"
              as={Link}
              to="/"
              style={{
                color: "#FF7223",
                fontFamily: "Poppins, sans-serif",
                fontStyle: "normal",
                fontSize: "24px",
                fontWeight: "600",
              }}
            >
              CurateLearn
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              style={toggleButtonStyle}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span
                className="navbar-toggler-icon"
                style={toggleIconStyle}
              ></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" in={isOpen}>
              <Nav style={centerNavStyle}>
                <Nav.Link
                  as={Link}
                  to="/"
                  style={navLinkStyle}
                  onClick={() => handleNavClick("/")}
                >
                  Overview
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/blog"
                  style={navLinkStyle}
                  onClick={() => handleNavClick("/blog")}
                >
                  Blog
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/faq"
                  style={navLinkStyle}
                  onClick={() => handleNavClick("/faq")}
                >
                  FAQ's
                </Nav.Link>
                {/* <Nav.Link
                  as={Link}
                  to="/contact"
                  style={navLinkStyle}
                  onClick={() => handleNavClick("/contact")}
                >
                  Contact Us
                </Nav.Link> */}
              </Nav>
              <Nav.Link
                className="space"
                as={Link}
                to="https://app.curatelearn.com"
                style={navLinkStyle}
              >
                Sign in
              </Nav.Link>
              <Button
                color="primary"
                style={buttonStyle}
                onClick={() =>
                  (window.location.href = "https://app.curatelearn.com/")
                }
              >
                {" "}
                Get Started
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Mulish&display=swap");

        @keyframes slideIn {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .animate-slide-in {
          animation: slideIn 0.8s ease-out forwards;
        }
        .get-started-btn {
          min-width: 150px;
          padding: 2px 10px;
          text-align: center;
        }
        .custom-title {
          font-size: 3rem;
          line-height: 1.2;
          opacity: 0;
        }
        .custom-lead {
          font-size: 1.2rem;
        }
        .demo-img-patten-bottom {
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        .demo-img-patten-bottom img {
          width: 100%;
          height: auto;
          display: block;
          margin-bottom: -1px; /* Removes the gap at the bottom */
        }
        @media (max-width: 576px) {
          .custom-title {
            font-size: 2rem;
          }
          .custom-lead {
            font-size: 1rem;
          }
          .space {
            margin-bottom: 15px;
          }
        }
      `}</style>
    </>
  );
};

export default MyNav;

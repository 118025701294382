import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  Button,
  Spinner,
  PaginationItem,
  PaginationLink,
  Pagination,
} from "reactstrap";
import img1 from "./../Images/blogimage.svg";
import img2 from "./../Images/blogimage2.svg";
import img3 from "./../Images/blogimage2.svg";
import img4 from "./../Images/blogimage3.svg";
import img5 from "./../Images/blogimage4.svg";
import img6 from "./../Images/blogimage5.svg";
import img7 from "./../Images/blogbanner.svg";
import blog1img from "./../Images/blog1.png";
import blog2img from "./../Images/blog2.png";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { fetchAllPost } from "../services/BlogServices";
import { formatDate } from "../utils";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard";
import BlogBtn from "./BlogBtn";

const divStyle = styled.div`
  display: flex;
`;

const gradientBackgroundStyle = {
  background: "linear-gradient(360deg,  #FFEADE 20%, #FCFCFC 100%)",
  height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [firstSlug, setFirstSlug] = useState("");
  const [data, setData] = useState([]);

  const {
    data: queryData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["ALL POSTS", currentPage],
    queryFn: () => fetchAllPost(currentPage),
    keepPreviousData: true,
  });

  const generateSlug = (title) => {
    return `${title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}`;
  };

  useEffect(() => {
    if (queryData) {
      const data = queryData.items;
      setData(data);
      console.log(data);

      setFirstSlug(generateSlug(data[0]?.title));
    }
  }, [queryData]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addEventListener("change", handleResize);

    return () => mediaQuery.removeEventListener("change", handleResize);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // if (isLoading) {
  //   return (
  //     <div className="h6 d-flex justify-content-center p-5">
  //       <Spinner
  //         style={{
  //           color: "#FF7223",
  //           height: "4rem",
  //           width: "4rem",
  //         }}
  //       >
  //         Loading...
  //       </Spinner>
  //     </div>
  //   );
  // }

  // if (isError || !queryData || !queryData.items) {
  //   return <p>Error loading posts or no data available.</p>;
  // }

  return (
    <React.Fragment>
      <section
        className="section pb-0 hero-section"
        style={gradientBackgroundStyle}
      >
        <div className="bg-overlay bg-overlay-pattern"></div>
        <Container>
          <Row className="justify-content-center mb-5">
            <Col md={8} className="text-center">
              <h1
                className="fw-bold mb-3"
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "600",
                  fontSize: isMobile ? "32px" : "64px",
                }}
              >
                Blog
              </h1>
              <p
                className="text-muted"
                style={{
                  fontWeight: "400",
                  fontFamily: "Mulish, sans-serif",
                  fontStyle: "normal",
                  fontSize: isMobile ? "14px" : "20px",
                }}
              >
                Empower Your Self-Learning Journey with a Wealth of Resources
                and Insights
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {isLoading ? (
        <div
          className="h6 p-5"
          style={{
            minHeight: "500px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner
            style={{
              color: "#FF7223",
              height: "4rem",
              width: "4rem",
            }}
          >
            Loading...
          </Spinner>
        </div>
      ) : !isError ? (
        <Container fluid className="py-5">
          <Container>
            <Row>
              <div
                className="shadow-sm p-0"
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column-reverse" : "row",
                  alignItems: "center",
                  marginTop: "-180px",
                  justifyContent: "center",
                  borderRadius: "41px",
                  padding: "0",
                  // margin: "auto",

                  // width: "80%",
                  // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
                }}
              >
                <div
                  style={{
                    // padding: "0",
                    background: "white",
                    height: "100%",
                    display: "flex",
                    alignItems: "end",
                    borderRadius: isMobile ? "41px" : "41px 0 0 41px",
                    padding: "40px",

                    width: "100%",
                  }}
                >
                  {/* <p
                  style={{
                    position: "relative",
                    top: 0,
                  }}
                >
                  New
                </p> */}
                  <div>
                    <p
                      style={{
                        color: "#767676",
                        fontWeight: "700",
                        fontFamily: "Mulish, sans-serif",
                        fontStyle: "normal",
                        fontSize: "14px",
                      }}
                    >
                      {formatDate(data[0]?.created_at)}
                    </p>
                    <h2
                      style={{
                        fontWeight: "600",
                        fontFamily: "Poppins, sans-serif",
                        fontStyle: "normal",
                        fontSize: isMobile ? "16px" : "24px",
                      }}
                    >
                      {data[0]?.title}
                    </h2>
                    <BlogBtn id={data[0]?.id} slug={firstSlug} />
                  </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "80%",
                    borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                    height: isMobile ? "auto" : "400px",
                  }}
                >
                  <img
                    style={{
                      borderRadius: isMobile ? "41px" : "0 41px 41px 0",
                      width: isMobile ? "100%" : "641px",
                      height: isMobile ? "380px" : "100%",
                      padding: "0",
                    }}
                    src={data[0]?.title_photo}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: isMobile ? "block" : "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "50px",
                  }}
                >
                  {data.slice(1).map((item, index) => {
                    const slug = generateSlug(item.title);
                    // console.log("slug", slug);
                    return (
                      <BlogCard
                        photo={item.title_photo}
                        createdAt={item.created_at}
                        title={item.title}
                        slug={slug}
                        id={item.id}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <Pagination>
                  {/* <PaginationItem>
                    <PaginationLink first href="#" />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#" previous />
                  </PaginationItem> */}
                  {Array.from({ length: queryData.pages }, (_, index) => (
                    <PaginationItem
                      key={index}
                      active={currentPage === index + 1}
                    >
                      <PaginationLink
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                  {/* <PaginationItem>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">4</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">5</PaginationLink>
                  </PaginationItem> */}
                  {/* <PaginationItem>
                    <PaginationLink href="#" next />
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#" last />
                  </PaginationItem> */}
                </Pagination>
              </div>
            </Row>
          </Container>
        </Container>
      ) : (
        <p>Error loading posts or no data available.</p>
      )}

      {/* </section> */}
    </React.Fragment>
  );
};

export default Blog;
